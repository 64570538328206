<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">问卷列表</div>
        <el-button type="primary" @click="createSurvey" class="cardTitleBtn">新建问卷</el-button>
      </el-col>
      <el-col :span="24">
         <el-table :data="surveyList" :default-sort = "{prop: 'name', order: 'desc'}" style="width: 100%">
                <el-table-column prop="name" label="名称" sortable></el-table-column>
                <el-table-column prop="updateTime" label="最近修改时间" sortable></el-table-column>
                <el-table-column prop="createTime" label="创建时间" sortable></el-table-column>
                <el-table-column fixed="right" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      @click="getDetail(scope.row)"
                      type="text"
                      size="mini"
                    >
                      详情
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
      </el-col>
   <el-col :span="24">
          <div class="block" style="margin-top: 20px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="recordList.nowPageNum"
              :page-sizes="[ 20, 40, 80]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="recordList.totalNum"
            >
            </el-pagination>
          </div>
        </el-col>
    </el-row>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      surveyList:[],
      recordList:{},
      pageSize:20,
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getSurveyList(1)
  },
  methods: {
    getSurveyList(pageNum) {
      let self = this;
      self.$axios
        .get("/survey/page?pageSize=" +self.pageSize +"&pageNum=" +(pageNum || 1), {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.surveyList = response.data.data.records;
          self.recordList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "问卷列表获取失败："+error.response.data.message,
            type: "warning",
          });
        });
    },
    getDetail(row) {
      let self = this;
      self.$router.push({ name: "survey_detail", query: { guid: row.guid } });
    },
    createSurvey() {
      let self = this;
      self.$router.push({ name: "survey_detail", query: { guid: null } });
    },
     handleSizeChange(val) {
      this.pageSize = val * 1;
      this.getSurveyPageNum()
    },
    handleCurrentChange(val) {
      this.getSurveyPageNum(val)
    },
    getSurveyPageNum(val) {
      this.getSurveyList(val);
    },
  },
};
</script>

<style scoped>
</style>